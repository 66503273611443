import CookieConsent from "cookieconsent";

// not sure why I can't just use the reference imported above
window.cookieconsent.initialise({
  palette: {
    popup: {
      background: "#237afc"
    },
    button: {
      background: "#fff",
      text: "#237afc"
    }
  },
  position: "bottom",
  content: {
    message: "<span class='cc-message-header'>GeoCerts' Use of Cookies</span>GeoCerts uses cookies to enhance your experience, to display customized content in accordance with your browser settings, and to help us better understand how you use our website. By continuing to browse or closing this cookie consent notice, you indicate your agreement. To learn more about the cookies we use see our",
    dismiss: "Got it!",
    link: "Cookies Policy.",
    href: "/cookie-policy"
  }
});

import 'cookieconsent/build/cookieconsent.min.css';
import '../stylesheets/cookieconsent';
