import { Notifier } from '@airbrake/browser'

const airbrake = new Notifier({
  projectId: 289691,
  projectKey: '51cabeff1598829f07f29cb209b75eec',
})

airbrake.addFilter((notice) => {
  notice.context.environment = process.env.RAILS_ENV

  // ignoring errors
  if (notice.context.environment === "development") {
    return null
  } else if (notice.errors && notice.errors.length === 1 && notice.errors[0].message.substring(0, 14) === "GraphQL error:") {
    // GraphQL errors we need to know about should be notified via the backend
    return null
  }

  return notice
})

export default airbrake
