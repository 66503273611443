import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
// import "channels"
import tippy from 'tippy.js';
import "../services/airbrake"
import "../services/cookieconsent";
// import '@fortawesome/fontawesome-pro/js/all';

// not modern javascript but still works
// import '../../../node_modules/bootstrap4-toggle/js/bootstrap4-toggle.min.js';
// import '../../../node_modules/bootstrap4-toggle/css/bootstrap4-toggle.min.css';

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()

tippy('[data-tippy-content]');

const application = Application.start()
const context = require.context("../marketing/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import 'tippy.js/dist/tippy.css';
